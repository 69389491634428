import React, { useEffect, useState } from "react";
import { Table, Button } from "react-bootstrap";
import EmployeeModulsDataService from "../dataservices/employeeModulsDataService";



const EmployeeModulsList = ({ getEmployeeModulId }) => {
  const [employeemoduls, setEmployeeModuls] = useState([]);
  useEffect(() => {
    getEmployeeModuls();
  }, []);

  const getEmployeeModuls = async () => {
    const data = await EmployeeModulsDataService.getAllEmployeeModuls();
    console.log(data.docs);
    setEmployeeModuls(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };
  const getEmployeeModulsModulsId = async () => {
    const moduldata = await EmployeeModulsDataService.getAllEmployeeModuls();
    setEmployeeModuls(moduldata.docs.map((doc) => ({ ...doc.moduldata(), id: doc.id, moduls: doc.moduldata().moduls })));
  };

  const deleteHandler = async (id) => {
    await EmployeeModulsDataService.deleteEmployeeModul(id);
    getEmployeeModuls();
  };
  return (
    <>
      <div className="mb-2">
        <Button variant="dark edit" onClick={getEmployeeModuls}>
          Refresh List
        </Button>
      </div>

      {/* <pre>{JSON.stringify(employeemoduls, undefined, 2)}</pre>} */}
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Employee ID</th>
            <th>Modul ID</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {employeemoduls.map((doc, index) => {
            return (
              <tr key={doc.id}>
                <td>{index + 1}</td>
                <td>{doc.userID}</td>
                <td>{doc.active}</td>
                <td>
                  <Button
                    variant="secondary"
                    className="edit"
                    onClick={(e) => getEmployeeModulId(doc.id)}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    className="delete"
                    onClick={(e) => deleteHandler(doc.id)}
                  >
                    Delete
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default EmployeeModulsList;