import Sidebar from "./components/sidebar/Sidebar"
import PeoplePage from "./pages/peopleList/peoplePage";
import Modullibrary from "./pages/modullibrary/modulLibrary";
import Modul from "./pages/modul/Modul";
import Roles from "./pages/roles/Roles";

import Login from "./components/login/Login";
import "./App.css"
import {useState, useEffect} from 'react'
import PrivateRoute from './components/auth/PrivateRoute'
import { auth } from "./firebase";
import { AuthProvider } from "./components/auth/AuthContext";
import { Navigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { BrowserRouter as Router, Routes, Route, Link, Outlet, } from "react-router-dom";
import Register from "./components/signup/SignUp";
import Profile from "./components/profile/Profile";
import EmployeeModulsList from "./components/employeemoduls/employeeModulsList";
import AddEmployeeModul from "./components/employeemoduls/addEmployeeModul";
import EmployeeProfile from "./pages/employeeprofile/employeeProfile";
import EmployeeModuls from "./components/employeemoduls/employeeModuls";
import Messages from "./pages/messages/Messages";
import Settings from "./pages/settings/Settings";
import PeopleProfile from "./pages/peopleList/peopleProfile";
import AddModulAssignment from "./pages/employeeprofile/addModulAssignment";
import EditModulAssignment from "./pages/employeeprofile/editModulAssignment";


function App() {

  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })
  }, [])

  return (
    <Router>
          <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
              <div className="container">
              <Sidebar/>
              <nav></nav>
              <Routes>
                <Route path="/" element={<Login />} />
                <Route exact path="/people" element={
                  <PrivateRoute>
                    <PeoplePage />
                  </PrivateRoute>  
                } />
                 <Route exact path="/people/:id" element={
                  <PrivateRoute>
                    <PeoplePage />
                  </PrivateRoute>  
                } />
                <Route exact path="/modulLibrary" element={
                  <PrivateRoute>
                    <Modullibrary />
                  </PrivateRoute>
                } />
                <Route exact path="/employeeModulsList" element={
                  <PrivateRoute>
                    <EmployeeModulsList />
                  </PrivateRoute>
                } />
                <Route exact path="/addEmployeeModuls" element={
                  <PrivateRoute>
                    <AddEmployeeModul />
                  </PrivateRoute>
                } />
                <Route exact path="/addModulAssignment" element={
                  <PrivateRoute>
                    <AddEmployeeModul />
                  </PrivateRoute>
                } />
                <Route path="/modul" element={<Modul />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/login" element={<Login />} />
                <Route exact path="/Profile" element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                } />
                <Route exact path="/employeeProfile/" element={
                  <PrivateRoute>
                    <EmployeeProfile />
                  </PrivateRoute>
                } />
                <Route exact path="/messages/" element={
                  <PrivateRoute>
                    <Messages />
                  </PrivateRoute>
                } />
                <Route exact path="/settings/" element={
                  <PrivateRoute>
                    <Settings />
                  </PrivateRoute>
                } />
                <Route path="employeeProfile/:employeeId" element={
                  <PrivateRoute>
                    <EmployeeProfile />
                  </PrivateRoute>
                } />
                <Route path="peopleProfile/:employeeId" element={
                  <PrivateRoute>
                    <PeopleProfile />
                  </PrivateRoute>
                } />
                <Route exact path="/employeeModuls/" element={
                  <PrivateRoute>
                    <EmployeeModuls />
                  </PrivateRoute>
                } />
                <Route exact path="/modulAssignment/" element={
                  <PrivateRoute>
                    <AddModulAssignment />
                  </PrivateRoute>
                } />
                <Route path="/register" element={<Register />} />
                <Route path="*" element={ <main style={{ padding: "1rem" }}> <p>Task failed successfully?</p> <p>We have no record of the requested record. This is an issue, please describe the issue and send an email to help@modul.work</p></main> }/>
              </Routes>
          </div>
          </AuthProvider>
    </Router>
);
}

export default App;
