//import { Link } from "react-router-dom";
import "./modul.css";
//import { Publish } from "@material-ui/icons";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

export default function Modul() {
    const [modulName, setModulname, ] = useState('')
    const [modulDescription, setModulDescription, ] = useState('')
    
    function handleSubmit(e) {
        e.preventDefault()
        if (modulName === '') {
            return
        }
        const modulCollectionRef = collection(db, 'modul')
        addDoc(modulCollectionRef, { modulName, modulDescription})    
            .then(response => {
                console.log(response)
            })
            .catch(error => {
                console.log(error.message)
            })
        alert(modulName)
    }
    return (
        <div>
            <h4>Add Modul</h4>
            <form onSubmit={handleSubmit}>
                <label htmlFor="modulName">Modul Name</label>
                <input id='modulName' type="text" value={modulName} onChange={ e => setModulname(e.target.value) }/>
                
                <label htmlFor="modulDescription">Modul Description</label>
                <input id='modulDescription' type="text" value={modulDescription} onChange={ e => setModulDescription(e.target.value) }/>
                <button type='submit'>Add Modul</button>
            </form>
        </div>
    )
}