import {useState} from 'react';
import './employeeprofile.css'
import { db } from '../../firebase'
import { collection, addDoc, Timestamp } from "firebase/firestore";
import { useAuthValue } from "../../components/auth/AuthContext";
import Modal from './Modal';

function AddModulAssignment({onClose, open}) {

  const [modulName, setModulName] = useState('')
  const [modulDescription, setModulDescription] = useState('')
  const [modulAssignment, setModulAssignment] = useState('')
  const user = useAuthValue()

  /* function to add new modulAssigment to firestore */
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'modulassignment'), {
        modulName: modulName,
        modulDescription: modulDescription,
        UID: user.currentUser.uid,
        created: Timestamp.now()
      })
      onClose()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Modal modalLable='Add Modul' onClose={onClose} open={open}>
      <form onSubmit={handleSubmit} className='addModulAssignment' name='addModulAssignment'>
        <input 
          type='text' 
          name='name' 
          onChange={(e) => setModulName(e.target.value)} 
          value={modulName}
          placeholder='Enter Modul title'/>
        <textarea 
          onChange={(e) => setModulDescription(e.target.value)}
          placeholder='Enter Modul decription'
          value={modulDescription}></textarea>
        <button type='submit'>Done</button>
      </form> 
    </Modal>
  )
}

export default AddModulAssignment