import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import './employeemoduls.css';
import EmployeeModulsDataService from "../dataservices/employeeModulsDataService";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container'
import CardHeader from "react-bootstrap/esm/CardHeader";
import ModulsDataService from "../dataservices/modulDataService";
import employeeDataService from "../dataservices/employeeDataService";
import { useAuthValue } from '../../components/auth/AuthContext';
import { collection, getDocs,query,where } from "firebase/firestore";
import { db } from "../../firebase";
import addmodul from './addmodul.png'
import AddModulAssignment from "../../pages/employeeprofile/addModulAssignment";
import EditModulAssignment from "../../pages/employeeprofile/editModulAssignment";


//This employeeModuls component displays the moduls assigned to the employee selected. This component is used in the EmployeeProfile component.

export default function EmployeeModuls() {  
  
//the employeeID is passed from the EmployeeList component OR from the EmployeeProfile component.
const [employeemoduls, setEmployeeModuls] = useState([]);
const getSelectedEmployeeId = useParams();

const currentUser = useAuthValue();
const [openAddModal, setOpenAddModal] = useState(false)
const [openEditModal, setOpenEditModal] = useState(false)

//Function to get the moduls for the logged in user's moduls.
function  getEmployeeModulsById(){
    getDocs(query(collection(db, "modulassignment"), where("UID", "==", currentUser.currentUser.uid))).then(response => {
    const data= response.docs.map(doc => ({
      id: doc.id,
      data: doc.data()
   }));
  setEmployeeModuls(data)
  console.log(data)

})
}
useEffect(() => {
  getEmployeeModulsById()
}, [])


  //todo: #17 change button to an icon, and make it open up a modal with the edit form
  return (
    <Container>
        <Grid container spacing={1} style={{padding: 24}}>
            {employeemoduls.map(modul => (
                <Grid item key={modul.data.id} xs={12} md={12} lg={12}>
                    <Card>
                        <CardHeader>{ modul.data.modulName }
                        <Button onClick={() => setOpenEditModal(true)} size="small">●●●</Button>
                          {openEditModal &&
                            <EditModulAssignment onClose={() => setOpenEditModal(false)} open={openEditModal}/>
                            }
                        </CardHeader>
                        { modul.data.modulDescription }
                    </Card>
                </Grid>
            ))}
        </Grid>
    {employeemoduls.map(modul => (
        <p key={modul.id}>{modul.title}</p>
    ))}
    <button class="addmodbtn" onClick={() => setOpenAddModal(true)}> <img src={addmodul} height ="78" width="78" /></button>
    {openAddModal &&
        <AddModulAssignment onClose={() => setOpenAddModal(false)} open={openAddModal}/>
      }
    </Container>
    
  );
};