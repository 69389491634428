import "./sidebar.css"
import React from "react"
import { Link, BrowserRouter, NavLink } from "react-router-dom"
import { useAuthValue } from "../auth/AuthContext"
import { auth } from "../../firebase"
import logo from './mwmix_logo.png';
import user from './user.png'
import userActive from './user.svg'
import messages from './messages.png'
import people from './team.png'
import management from './management.png'
import notifications from './notifications.png'
import settings from './settings.png'

//import Login from "../../Login";

// Filters applied with calc from https://codepen.io/sosuke/pen/Pjoqqp

export default function Sidebar() {
  //Conditional display of buttons according to User being logged in or not TODO Add role based conditional display
  return (
    <div className="sidebar">
        <div className="sidebarWrapper">
            <div className="topLeft">
                <img src={logo} alt="Logo" className="logo" />
            </div>
            <div className="sidebarMenu">
                <ul className="sidebarList">
                    <li className="sidebarListItem">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                    to='/employeeProfile'>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="rgb(93, 95, 93)" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.62 26.12C13.64 25.34 14.78 24.7248 16.04 24.2744C17.3 23.8248 18.62 23.6 20 23.6C21.38 23.6 22.7 23.8248 23.96 24.2744C25.22 24.7248 26.36 25.34 27.38 26.12C28.08 25.3 28.6252 24.37 29.0156 23.33C29.4052 22.29 29.6 21.18 29.6 20C29.6 17.34 28.6652 15.0748 26.7956 13.2044C24.9252 11.3348 22.66 10.4 20 10.4C17.34 10.4 15.0752 11.3348 13.2056 13.2044C11.3352 15.0748 10.4 17.34 10.4 20C10.4 21.18 10.5952 22.29 10.9856 23.33C11.3752 24.37 11.92 25.3 12.62 26.12ZM20 21.2C18.82 21.2 17.8248 20.7952 17.0144 19.9856C16.2048 19.1752 15.8 18.18 15.8 17C15.8 15.82 16.2048 14.8248 17.0144 14.0144C17.8248 13.2048 18.82 12.8 20 12.8C21.18 12.8 22.1752 13.2048 22.9856 14.0144C23.7952 14.8248 24.2 15.82 24.2 17C24.2 18.18 23.7952 19.1752 22.9856 19.9856C22.1752 20.7952 21.18 21.2 20 21.2ZM20 32C18.34 32 16.78 31.6848 15.32 31.0544C13.86 30.4248 12.59 29.57 11.51 28.49C10.43 27.41 9.5752 26.14 8.9456 24.68C8.3152 23.22 8 21.66 8 20C8 18.34 8.3152 16.78 8.9456 15.32C9.5752 13.86 10.43 12.59 11.51 11.51C12.59 10.43 13.86 9.5748 15.32 8.9444C16.78 8.3148 18.34 8 20 8C21.66 8 23.22 8.3148 24.68 8.9444C26.14 9.5748 27.41 10.43 28.49 11.51C29.57 12.59 30.4248 13.86 31.0544 15.32C31.6848 16.78 32 18.34 32 20C32 21.66 31.6848 23.22 31.0544 24.68C30.4248 26.14 29.57 27.41 28.49 28.49C27.41 29.57 26.14 30.4248 24.68 31.0544C23.22 31.6848 21.66 32 20 32Z" stroke="black" fill="black"/>
                      </svg>
                      
                      </NavLink>
                    </li>
                    <li className="sidebarListItem">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                    to='/messages'>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.4 29C9.74 29 9.1752 28.7799 8.7056 28.3396C8.2352 27.8986 8 27.3687 8 26.75V13.25C8 12.6313 8.2352 12.1017 8.7056 11.6615C9.1752 11.2205 9.74 11 10.4 11H29.6C30.26 11 30.8252 11.2205 31.2956 11.6615C31.7652 12.1017 32 12.6313 32 13.25V26.75C32 27.3687 31.7652 27.8986 31.2956 28.3396C30.8252 28.7799 30.26 29 29.6 29H10.4ZM20 21.125L29.6 15.5V13.25L20 18.875L10.4 13.25V15.5L20 21.125Z" stroke="black" fill="black"/>
                      </svg>
                    </NavLink>
                    </li>
                    <li className="sidebarListItem">
                      <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                      to='/people'>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 29V25.85C8 25.2125 8.15927 24.6264 8.47782 24.0916C8.79564 23.5576 9.21818 23.15 9.74545 22.8688C10.8727 22.2875 12.0182 21.8514 13.1818 21.5604C14.3455 21.2701 15.5273 21.125 16.7273 21.125C17.9273 21.125 19.1091 21.2701 20.2727 21.5604C21.4364 21.8514 22.5818 22.2875 23.7091 22.8688C24.2364 23.15 24.6589 23.5576 24.9767 24.0916C25.2953 24.6264 25.4545 25.2125 25.4545 25.85V29H8ZM16.7273 20C15.5273 20 14.5 19.5594 13.6455 18.6781C12.7909 17.7969 12.3636 16.7375 12.3636 15.5C12.3636 14.2625 12.7909 13.2031 13.6455 12.3219C14.5 11.4406 15.5273 11 16.7273 11C17.9273 11 18.9545 11.4406 19.8091 12.3219C20.6636 13.2031 21.0909 14.2625 21.0909 15.5C21.0909 16.7375 20.6636 17.7969 19.8091 18.6781C18.9545 19.5594 17.9273 20 16.7273 20ZM27.6364 15.5C27.6364 16.7375 27.2091 17.7969 26.3545 18.6781C25.5 19.5594 24.4727 20 23.2727 20C23.0727 20 22.8182 19.9767 22.5091 19.9303C22.2 19.883 21.9455 19.8313 21.7455 19.775C22.2364 19.175 22.6135 18.5094 22.8767 17.7781C23.1407 17.0469 23.2727 16.2875 23.2727 15.5C23.2727 14.7125 23.1407 13.9531 22.8767 13.2219C22.6135 12.4906 22.2364 11.825 21.7455 11.225C22 11.1312 22.2545 11.0701 22.5091 11.0416C22.7636 11.0139 23.0182 11 23.2727 11C24.4727 11 25.5 11.4406 26.3545 12.3219C27.2091 13.2031 27.6364 14.2625 27.6364 15.5ZM27.6364 29V25.625C27.6364 24.8 27.4138 24.0076 26.9687 23.2479C26.5229 22.4889 25.8909 21.8375 25.0727 21.2937C26 21.4062 26.8727 21.5982 27.6909 21.8698C28.5091 22.142 29.2727 22.475 29.9818 22.8688C30.6364 23.2438 31.1364 23.6607 31.4818 24.1198C31.8273 24.5795 32 25.0813 32 25.625V29H27.6364Z" stroke="black" fill="black"/>
                      </svg>  
                      </NavLink>
                    </li>
                    <li className="sidebarListItem">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                      to='/modulLibrary'>
                      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M29.9 9H10.1C9.49107 9 9 9.49107 9 10.1V29.9C9 30.5089 9.49107 31 10.1 31H29.9C30.5089 31 31 30.5089 31 29.9V10.1C31 9.49107 30.5089 9 29.9 9ZM17.0683 24.8862H15.1138V15.1138H17.0683V24.8862ZM24.8911 24.8862H19.0228V22.9317H24.8911V24.8862ZM24.8911 20.9772H19.0228V19.0228H24.8911V20.9772ZM24.8911 17.0634H19.0228V15.1089H24.8911V17.0634Z" stroke="black" fill="black"/>
                      </svg>
                    </NavLink>
                    </li>
                </ul>
            </div>
            <div className="sidebarMenuBottom">
                <ul className="sidebarList">
                    <li className="sidebarListItem">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                      to='/notifications'>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3604 10H20.6396C20.8548 10 21.0459 10.1377 21.114 10.3419L21.7291 12.1874C22.6571 12.3918 23.5247 12.7574 24.301 13.2533L26.0416 12.383C26.2341 12.2867 26.4666 12.3245 26.6188 12.4766L27.5234 13.3812C27.6755 13.5334 27.7133 13.7659 27.617 13.9584L26.7467 15.699C27.2426 16.4753 27.6082 17.3429 27.8126 18.2709L29.6581 18.886C29.8623 18.9541 30 19.1452 30 19.3604V20.6396C30 20.8548 29.8623 21.0459 29.6581 21.114L27.8126 21.7291C27.6082 22.6571 27.2426 23.5247 26.7467 24.301L27.617 26.0416C27.7132 26.2341 27.6755 26.4666 27.5233 26.6188L26.6188 27.5234C26.4666 27.6755 26.2341 27.7133 26.0416 27.617L24.301 26.7467C23.5247 27.2426 22.6571 27.6082 21.7291 27.8126L21.114 29.6581C21.0459 29.8623 20.8548 30 20.6396 30H19.3604C19.1452 30 18.9541 29.8623 18.886 29.6581L18.2709 27.8126C17.3429 27.6082 16.4753 27.2426 15.699 26.7467L13.9584 27.617C13.7659 27.7132 13.5334 27.6755 13.3812 27.5233L12.4767 26.6188C12.3245 26.4666 12.2868 26.2341 12.383 26.0416L13.2533 24.301C12.7574 23.5247 12.3918 22.6571 12.1874 21.7291L10.3419 21.114C10.1377 21.0459 10 20.8548 10 20.6396V19.3604C10 19.1452 10.1377 18.9541 10.3419 18.886L12.1874 18.2709C12.3918 17.3429 12.7574 16.4753 13.2533 15.699L12.383 13.9584C12.2867 13.7659 12.3245 13.5334 12.4766 13.3812L13.3812 12.4767C13.5334 12.3245 13.7659 12.2867 13.9584 12.383L15.699 13.2533C16.4753 12.7574 17.3429 12.3918 18.2709 12.1874L18.886 10.3419C18.9541 10.1377 19.1452 10 19.3604 10ZM20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20C16 22.2091 17.7909 24 20 24Z" stroke="black" fill="black"/>
                      </svg>
                    </NavLink>
                    </li>
                    <li className="sidebarListItem">
                    <NavLink className={({ isActive }) => (isActive ? "active" : "inactive")}
                      to='/settings'>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11 27V25H13.25V17C13.25 15.6167 13.7188 14.3873 14.6562 13.312C15.5938 12.2373 16.8125 11.5333 18.3125 11.2V10.5C18.3125 10.0833 18.4767 9.72933 18.8052 9.438C19.133 9.146 19.5312 9 20 9C20.4688 9 20.867 9.146 21.1947 9.438C21.5232 9.72933 21.6875 10.0833 21.6875 10.5V11.2C23.1875 11.5333 24.4062 12.2373 25.3438 13.312C26.2812 14.3873 26.75 15.6167 26.75 17V25H29V27H11ZM20 31C19.3813 31 18.8518 30.8043 18.4115 30.413C17.9705 30.021 17.75 29.55 17.75 29H22.25C22.25 29.55 22.0299 30.021 21.5896 30.413C21.1486 30.8043 20.6187 31 20 31Z" stroke="black" fill="black"/>
                        </svg>
                    </NavLink>
                    </li>
                </ul>
            </div>
            
        </div>

    </div>
  )
}
