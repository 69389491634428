import { db, auth } from "../../firebase";
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const modulCollectionRef = collection(db, "modul");

class ModulsDataService {
    //addEmployees = (newEmployee) => {
    //    return addDoc(employeeCollectionRef, newEmployee);
    //};

    //updateEmployee = (id, updatedEmployee) => {
    //    const employeeDoc = doc(db, "employees", id);
    //    return updateDoc(employeeDoc, updatedEmployee);
    //};

    //deleteEmployee = (id) => {
    //    const employeeDoc = doc(db, "employees", id);
    //    return deleteDoc(employeeDoc);
    //};

    getAllModuls = () => {
     return getDocs(modulCollectionRef);
    };

    getModul = (id) => {
        const modulDoc = doc(db, "modul", id)
        return getDoc(modulDoc);
    };
    
}

export default new ModulsDataService();