import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import PeopleList from "./peopleList"
import PeopleListDetails from "./peopleListDetails"

const useStyles = makeStyles({
  peopleListSection: {
    width: '100%',
    marginLeft:"30px"
    }
});

export default function PeoplePage() {
  const classes = useStyles();

  return (
    <Grid container component={Paper} className={classes.peopleListSection}>
      <PeopleList/>
      <PeopleListDetails />
    </Grid>
  );
}