import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import Card from "@material-ui/core/Card";
import CardHeader from "react-bootstrap/esm/CardHeader";
import addmodul from "./addmodul.png";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";
import CreateIcon from "@material-ui/icons/Create";
import StatusIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles({
  pancilIcon: {
    margin: "28px",
  },
  StatusIcon:{
    color:"white",
    backgroundColor:"#00a67e",
    borderRadius:"50px 50px",
    width:"60px",
    textAlign:"center"
  }
});
export default function PeopleListDetails() {
  const classes = useStyles();
  const [employeemoduls, setEmployeeModuls] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const getSelectedEmployeeId = useParams();

  function getEmployeeModulsById() {
    //Get selected emplyee data by employee UID
    getDocs(
      query(
        collection(db, "employees"),
        where("UID", "==", getSelectedEmployeeId.id)
      )
    ).then((response) => {
      const data = response.docs.map((doc) => ({
        data: doc.data(),
      }));
      setEmployeeData(data);
      // console.log(data)
    });
    //Get selected emplyee Module data by employee UID
    getDocs(
      query(
        collection(db, "modulassignment"),
        where("UID", "==", getSelectedEmployeeId.id)
      )
    ).then((response) => {
      const data = response.docs.map((doc) => ({
        data: doc.data(),
      }));
      setEmployeeModuls(data);
    });
  }

  useEffect(() => {
    getSelectedEmployeeId.id && getEmployeeModulsById();
  }, [getSelectedEmployeeId]);

  return (
    <>
      {employeeData && employeeData.length > 0 && (
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={11}>
              <List>
                <ListItem key={employeeData[0].data.UID}>
                  <ListItemIcon>
                    <Avatar
                      alt={employeeData[0].data.name}
                      src={employeeData[0].data.imageUrl}
                    />
                  </ListItemIcon>
                  <Grid>
                    <Grid container>
                      <Grid item xs={11}>
                        <ListItemText
                          primary={employeeData[0].data.name}
                        ></ListItemText>
                      </Grid>
                      <Grid item xs={1}>
                        <ListItemText
                          align="left"
                          className={classes.StatusIcon}
                          primary={employeeData[0].data.status}
                        ></ListItemText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <ListItemText
                        align="left"
                        secondary={
                          employeeData[0].data.jobTitle + " | " + employeeData[0].data.department
                        }
                      ></ListItemText>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid xs={1}>
              <CreateIcon className={classes.pancilIcon} />
            </Grid>
          </Grid>
          <Divider />
          <Grid container spacing={1} style={{ padding: 24 }}>
            {employeemoduls.map((modul) => (
              <Grid item key={modul.data.id} xs={12} md={12} lg={12}>
                <Card>
                  <CardHeader>
                    {modul.data.modulName}
                    <Button size="small">●●●</Button>
                  </CardHeader>
                  {modul.data.modulDescription}
                </Card>
              </Grid>
            ))}
          </Grid>
          {employeemoduls.map((modul) => (
            <p key={modul.id}>{modul.title}</p>
          ))}
          <button class="addmodbtn">
            {" "}
            <img src={addmodul} height="78" width="78" />
          </button>
        </Grid>
      )}
    </>
  );
}