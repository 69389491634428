import "./modullibrary.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
//import { modulRows } from "../../dummyData";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

  //const [data, setData] = useState(modulRows);
  
  //const [input, setInput] = useState('');
  //const [modul, setModul] =useState([]);
  
  //useEffect(() => {
  //  db.collection('modul').onSnapshot(snapshot => {
  //    setModul(snapshot.docs.map(doc => ({id:doc.id, modul:doc.data().modul})))
  //  })
  //}, []);

export default function ModulList() {

  const handleDelete = (id) => {
    setModul(modul.filter((item) => item.id !== id));
  };
    const [modul, setModul] = useState([])

    useEffect(() => {
      getModul()
    }, [])

    function getModul() {
      const modulCollectionRef = collection(db, 'modul')
      getDocs(modulCollectionRef)
        .then(response => {
          console.log(response.docs)
          const modl = response.docs.map(doc => ({
            data: doc.data(), 
            id: doc.id,
            modulName: doc.data().modulName,
            modulDescription: doc.data().modulDescription,
            status: doc.status,
        }))
        setModul(modl)
      })
        //.catch(error => console.log(error.message))
    }
  
  const columns = [
    //{ field: "id", headerName: "ID", width: 90 },
    {
      field: "modulName",
      headerName: "Modul Name",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="modulListModul">
            
            {params.row.modulName}
          </div>
        );
      },
    },
    {
      field: "modulDescription",
      headerName: "Description",
      width: 1000,
      renderCell: (params) => {
        return (
          <div className="modulListModul">
            
            {params.row.modulDescription}
          </div>
        );
      },
    },
    //{
    //  field: "status",
    //  headerName: "Status",
    //  width: 120,
    //},
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/Modul/" + params.row.id}>
              <button className="modulListEdit">Edit</button>
            </Link>
            <DeleteOutline
              className="modulListDelete"
              onClick={() => handleDelete(params.row.id)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="modulList">
      <DataGrid
        rows={modul}
        disableSelectionOnClick
        columns={columns}
        //pageSize={8}
        checkboxSelection
      />
    </div>
  );
}