import { db, auth } from "../../firebase";
import { collection, getDocs, getDoc, addDoc, updateDoc, deleteDoc, doc } from "firebase/firestore";

const emoCollectionRef = collection(db, "modulassignment");

class EmployeeModulsDataService {
    //method to add a new employeeModul document to the employeeModuls collection in firestore
    addEmployeeModuls = (newEmployeeModul) => {
        return addDoc(emoCollectionRef, newEmployeeModul);
    };

    //Method to edit an employee modul
    updateEmployeeModul = (id, updatedEmployeeModul) => {
        const employeeModulDoc = doc(db, "modulassignment", id);
        return updateDoc(employeeModulDoc, updatedEmployeeModul);
    };

    //Method to
    deleteEmployeeModul = (id) => {
        const emoDoc = doc(db, "modulassignment", id);
        return deleteDoc(emoDoc);
    };

    //Method to get all the employeeModuls from the database
    getAllEmployeeModuls = () => {
     return getDocs(emoCollectionRef);
    };

    //Method for getting the employeeModuls assigned to a specific employee
    getEmployeeModuls = (id) => {
        const employeeModulDoc = doc(db, "modulassignment", id)
        return getDoc(employeeModulDoc);
    };

    // Todo: userId should be passed from employeeProfile when the user accesses it directly.
    getEmployeeModulsByUserId = (userID) => {
        const employeeModulDoc = doc(db, "modulassignment", userID)
        return getDoc(employeeModulDoc);
    };

    // Todo: userId should be passed from employeeProfile when the user accesses it directly.
    getEmployeeModulsByUserId = (employeeID) => {
        const employeeModulDoc = doc(db, "modulassignment", employeeID)
        return getDoc(employeeModulDoc);
    };
    
    
}

export default new EmployeeModulsDataService();