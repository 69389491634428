import { initializeApp, } from "firebase/app";
import firebase from 'firebase/compat/app';
import { GoogleAuthProvider, onAuthStateChanged, getAuth, signInWithPopup, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut,
} from "firebase/auth";
import { getFirestore, query, getDocs, collection, where, addDoc, } from "firebase/firestore";

//const firebaseConfig = {
//    apiKey: "AIzaSyDoLzDdkp0j85vLUelxsSjBg78fNxGlaeQ",
//    authDomain: "modulwork-aa09a.firebaseapp.com",
//    projectId: "modulwork-aa09a",
//    storageBucket: "modulwork-aa09a.appspot.com",
//    messagingSenderId: "513552410088",
//    appId: "1:513552410088:web:c8b801c3cbca01bbeef05d",
//    measurementId: "G-H94TBV868N"
//  };

 //LSH configuration
const firebaseConfig = {
  apiKey: "AIzaSyCt0QEniBSJF__KiprQIZEaoFeZ4plffPU",
  authDomain: "moduldev.firebaseapp.com",
  projectId: "moduldev",
  storageBucket: "moduldev.appspot.com",
  messagingSenderId: "547228964007",
  appId: "1:547228964007:web:f5c3c0fbad840bc05a3782"
};


//Firebase is declared for authentication and database usage.

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const googleProvider = new GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  firebase,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};