import React, { useState, useEffect } from "react";
import EmployeeModulsService from "./employeeModuls";
import { Form, Alert, InputGroup, Button, ButtonGroup } from "react-bootstrap";
import EmployeeModulsDataService from "../dataservices/employeeModulsDataService";


const AddEmployeeModul = ({ id, setEmployeeModulId }) => {
    const [moduls, setModuls] = useState("");
    const [userID, setUserID] = useState("");
    //const [employeeID, setEmployeeID] = usestate("");
    const [active, setActive] = useState(true);
    const [status, setStatus] = useState(true);   
    const [message, setMessage] = useState({ error: false, msg: "" });

    const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage("");
      if (userID === "" || moduls === "") {
        setMessage({ error: true, msg: "All fields are mandatory!" });
        return;
      }
      const newEmployeeModul = {
        userID,
        moduls,
        active,
      };
      console.log(newEmployeeModul);
  
      try {
        if (id !== undefined && id !== "") {
          await EmployeeModulsDataService.updateEmployeeModuls(id, newEmployeeModul);
          setEmployeeModulId("");
          setMessage({ error: false, msg: "Updated successfully!" });
        } else {
          await EmployeeModulsDataService.addEmployeeModuls(newEmployeeModul);
          setMessage({ error: false, msg: "New Employee Modul added successfully!" });
        }
      } catch (err) {
        setMessage({ error: true, msg: err.message });
      }
  
      setUserID("");
      setModuls("");
      setActive("");
      //setEmployeeID("");
    };
  
    const editHandler = async () => {
      setMessage("");
      try {
        const docSnap = await EmployeeModulsDataService.getEmployeeModuls(id);
        console.log("the record is :", docSnap.data());
        setUserID(docSnap.data().userID);
        setModuls(docSnap.data().moduls);
        setActive(docSnap.data().status);
      } catch (err) {
        setMessage({ error: true, msg: err.message });
      }
    };
  
    useEffect(() => {
      console.log("The id here is : ", id);
      if (id !== undefined && id !== "") {
        editHandler();
      }
    }, [id]);
    return (
      <>
        <div className="p-4 box">
          {message?.msg && (
            <Alert
              variant={message?.error ? "danger" : "success"}
              dismissible
              onClose={() => setMessage("")}
            >
              {message?.msg}
            </Alert>
          )}
  
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formUserID">
              <InputGroup>
                <InputGroup.Text id="formUserID">Modul Title</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="User"
                  value={userID}
                  onChange={(e) => setUserID(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
  
            <Form.Group className="mb-3" controlId="formBookAuthor">
              <InputGroup>
                <InputGroup.Text id="formBookAuthor">Modul Description</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Modul ID"
                  value={moduls}
                  onChange={(e) => setModuls(e.target.value)}
                />
              </InputGroup>
            </Form.Group>
            <ButtonGroup aria-label="Basic example" className="mb-3">
              <Button
                disabled={active}
                variant="success"
                onClick={(e) => {
                  setStatus("Available");
                  setActive(true);
                }}
              >
                Available
              </Button>
              <Button
                variant="danger"
                disabled={!active}
                onClick={(e) => {
                  setStatus("Not Available");
                  setActive(false);
                }}
              >
                Not Available
              </Button>
            </ButtonGroup>
            <div className="d-grid gap-2">
              <Button variant="primary" type="Submit">
                Add/ Update
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  };
  
  export default AddEmployeeModul;