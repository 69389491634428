import "./peoplelist.css";
import { DataGrid } from "@material-ui/data-grid";
import { DeleteOutline } from "@material-ui/icons";
import { Link,useNavigate   } from "react-router-dom";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { collection, getDocs } from "firebase/firestore";

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import AddIcon from '@material-ui/icons/Add';
import StatusIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles({
              peopleListSection: {
                width: '100%',
                marginLeft:"30px"
                },
              headBG: {
                  backgroundColor: '#e0e0e0'
              },
              status:{
              marginLeft:"30px"
              },
             
        });

export default function PeopleList(){
  const navigate = useNavigate();
  const classes = useStyles();
  const handleDelete = (id) => {
    setEmployee(employees.filter((item) => item.id !== id));
  };
  
  const [employees, setEmployee] = useState([])
  const [searchField, setSearchField] = useState("")


    useEffect(() => {
      getEmployees()
    }, [])

    function getEmployees() {
      const employeeCollectionRef = collection(db, 'employees')
      getDocs(employeeCollectionRef)
        .then(response => {
          //  console.log(response.docs)
          const usr = response.docs.map(doc => ({
            data: doc.data(), 
            id: doc.id,
            UID: doc.data().UID,
            name: doc.data().name,
            email: doc.data().email,
            jobTitle: doc.data().jobTitle,
            status: doc.data().status,
            startDate: doc.data().startDate,
        }))
        setEmployee(usr)
      })
        //.catch(error => console.log(error.message))
    }
  const columns = [
    //{ field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Name",
      width: 230,
    },
    { field: "email", headerName: "Email", width: 250 },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      width: 260,
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Link to={"/peopleProfile/" + params.row.id}>
              <button className="employeeListEdit">Details</button>
            </Link>
          </>
        );
      },
    },
  ];

  const onSearchChange = e =>
  setSearchField(e.target.value);

  const filteredEmp = employees.filter(emp => {
    return emp.name.toLowerCase().includes(searchField.toLowerCase());
  });

  const handleFilterList=()=>{
    let filteredRes =filteredEmp.sort((a, b) => a.name.localeCompare(b.name))
    setEmployee(filteredRes)
  }




  return (
    
        <Grid item xs={3} >
            <List>
              <ListItem >
                    <Grid container  style={{margin: '10px'}}>
                          <Grid item xs={10}>
                              <Typography variant="h5" >People</Typography> 
                          </Grid>
                          <Grid xs={1} align="right">
                                <FilterListRoundedIcon onClick={handleFilterList}  />
                          </Grid>
                          <Grid xs={1} align="right">
                                <AddIcon  />
                          </Grid>
                      </Grid>
               </ListItem>
            </List>
            <Divider />
            <Grid item xs={16} style={{padding: '10px'}}>
                <TextField id="outlined-basic-email" label="Search"  onChange={onSearchChange} variant="outlined" fullWidth />
            </Grid>
            <Divider />
            <List>
                  {filteredEmp.map((item, index) => (
                        
                    <ListItem button key={index} onClick={()=>navigate(`/people/${item.UID}`)}   >
                          <ListItemIcon>
                              <Avatar alt={item.name} src={item.image} />
                          </ListItemIcon>
                              <Grid container>
                                <Grid item xs={12}>
                                    <ListItemText align="left" primary={item.name}></ListItemText>
                                </Grid>
                                <Grid item xs={12}>
                                    <ListItemText align="left" secondary={item.jobTitle}></ListItemText>
                                </Grid>
                              </Grid>
                           {
                              item.status==="active"?    <StatusIcon style={{color: "#00a67e"}} align="right" className={classes.status} />:
                              <StatusIcon style={{color: "red"}} align="right" className={classes.status} />
                            }
                      </ListItem>
                      
                    ))}
            </List>
        </Grid>
      
  
    // <div className="employeeList">
    //   <DataGrid
    //     rows={employees}
    //     disableSelectionOnClick
    //     columns={columns}
    //     //pageSize={4}
    //     checkboxSelection
    //   />
    // </div>
  );
}
