import Modal from "./Modal"
import {useState} from 'react'
import './employeeprofile.css'
import { doc, updateDoc } from "firebase/firestore";
import {db} from '../../firebase'

function EditModulAssignment({open, onClose, toEditModulName, toEditModulDescription, id}) {

  const [modulName, setModulName] = useState(toEditModulName)
  const [modulDescription, setModulDescription] = useState(toEditModulDescription)

  console.log(modulName, modulDescription)

  /* function to update firestore */
  const handleUpdate = async (e) => {
    e.preventDefault()
    const modulDocRef = doc(db, 'modulassignment', id)
    try{
      await updateDoc(modulDocRef, {
        modulName: modulName,
        modulDescription: modulDescription
      })
      onClose()
    } catch (err) {
      alert(err)
    }
    
  }

  return (
    <Modal modalLable='Edit Modul' onClose={onClose} open={open}>
      <form onSubmit={handleUpdate} className='editModulAssignment'>
        <input type='text' name='modulName' onChange={(e) => setModulName(e.target.value)} value={modulName}/>
        <textarea onChange={(e) => setModulDescription(e.target.value)} value={modulDescription}></textarea>
        <button type='submit'>Update</button>
      </form> 
    </Modal>
  )
}

export default EditModulAssignment