import React, { useEffect, useRef, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
import { Table, Button } from "react-bootstrap";
import '../../components/employeemoduls/employeemoduls.css';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container'
import CardHeader from "react-bootstrap/esm/CardHeader";
import { useAuthValue } from '../../components/auth/AuthContext';
import { collection, getDocs,query,where } from "firebase/firestore";
import { db } from "../../firebase";
import addmodul from './addmodul.png'


//This employeeModuls component displays the moduls assigned to the employee selected. This component is used in the EmployeeProfile component.

export default function PeopleModuls() {  
  
//the employeeID is passed from the EmployeeList component OR from the EmployeeProfile component.
const [employeemoduls, setEmployeeModuls] = useState([]);
const getSelectedEmployeeId = useParams();
//console.log(getSelectedEmployeeId);

  //EmployeeModulsService fetches the moduls assigned to the employee selected from the employeeModuls collection in firestore by using the employeeID in getSelectedEmployeeId and fetching data from the employeeModulsDataService.
  //const getEmployeeModuls = async () => {
  //  const data = await EmployeeModulsDataService.getEmployeeModuls(getSelectedEmployeeId.id);
  //  console.log(data.docs);
  //  setEmployeeModuls(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  // }  

const currentUser = useAuthValue();

//Function to get the moduls for the selected user's moduls.
function  getEmployeeModulsById(){
    getDocs(query(collection(db, "modulassignment"), where("employeeID", "==", getSelectedEmployeeId.employeeId))).then(response => {
    const data= response.docs.map(doc => ({
      data: doc.data()
   }));
  setEmployeeModuls(data)
  console.log(data)

})
}
useEffect(() => {
  getEmployeeModulsById()
}, [])


  //todo: #17 change button to an icon, and make it open up a modal with the edit form
  return (
    <Container>
        <Grid container spacing={1} style={{padding: 24}}>
            {employeemoduls.map(modul => (
                <Grid item key={modul.data.id} xs={12} md={12} lg={12}>
                    <Card>
                        <CardHeader>{ modul.data.modulName }
                        <Button size="small">●●●</Button>
                        </CardHeader>
                        { modul.data.modulDescription }
                    </Card>
                </Grid>
            ))}
        </Grid>
    {employeemoduls.map(modul => (
        <p key={modul.id}>{modul.title}</p>
    ))}
    <button class="addmodbtn"> <img src={addmodul} height ="78" width="78" /></button>
    </Container>
    
  );
};