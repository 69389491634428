import React from 'react';
import "./employeeprofile.css";
import { collection, doc, getDoc, getDocs, onSnapshot, where, query, limit, } from "firebase/firestore";
import { useEffect, useState, } from "react";
import { db } from "../../firebase";
import { Outlet, useParams } from 'react-router-dom';
import { useAuthValue } from '../../components/auth/AuthContext';

//This component renders the employee profile header with the name of the employee, jobtitle and department

export default function PeopleProfileHeader() {

    //functions to set the user and employee variables
    const currentUser = useAuthValue();
    //console.log(currentUser.currentUser);
    
    const [employeeData, setEmployeeData] = useState({
        employee: {},
    });

    const [employeeData2, setEmployeeData2] = useState({
      employee: {},
  });

    const getEmployeeId = useParams();
    
    console.log(employeeData)
    
//Todo: change the query to get get the doc by using getDoc()


   //use params to get the employee id from the url and get the employee data from the employees collection in firestore with getDoc()

    function getEmployeeData() {
      const employeeCollectionRef = doc(db, 'employees', getEmployeeId.employeeId)
      getDoc(employeeCollectionRef)
        .then(doc => {
          setEmployeeData(doc.data());
        }
    )}


        

    
  useEffect(() => {
    getEmployeeData()
  }, [])


  return (
    <div className="employeeProfileHeader">
                <div className="employeeProfileHeaderName"> {employeeData.name} </div>
                <div className="employeeProfileHeaderJobTitle"> {employeeData.jobTitle} | {employeeData.department}</div>
            </div>
  )
}
