import React from 'react'
import "./employeeprofile.css";
import { Link, Outlet, useParams } from "react-router-dom";
import { useEffect, useState, } from "react";
import { db } from "../../firebase";
import { collection, doc, getDoc, getDocs, onSnapshot, where, query, limit, } from "firebase/firestore";
import EmployeeDataService from '../../components/dataservices/employeeDataService';
import EmployeeModuls from '../../components/employeemoduls/employeeModuls';
import { useAuthValue } from '../../components/auth/AuthContext';
import EmployeeProfileHeader from './employeeProfileHeader';


export default function EmployeeProfile() {
    
    //functions to set the user and employee variables
    const currentUser = useAuthValue();
    //console.log(currentUser.currentUser);
    //const getEmployeeId = "4MfMkibz1gmJSUhBBsYr";
    //console.log(getEmployeeId);
  

//Display the employee data and their current moduls
    
    

      //EmployeeProfileHeader renders the name of the employee and other relevent information
      //Another level of nesting is used to render the moduls assigned to the employee by reading from employeeModuls array and parse the names of Moduls from the moduls collection in firestore
      
      return (
        <div className="employeeProfile">     
            <EmployeeProfileHeader />
            <EmployeeModuls />
            <Outlet />
        </div>            
            
      );
  }