import React from 'react';
import "./employeeprofile.css";
import { collection, doc, getDoc, getDocs, onSnapshot, where, query, limit, } from "firebase/firestore";
import { useEffect, useState, } from "react";
import { db } from "../../firebase";
import { Outlet, useParams } from 'react-router-dom';
import { useAuthValue } from '../../components/auth/AuthContext';

//This component renders the employee profile header with the name of the employee, jobtitle and department

export default function EmployeeProfileHeader() {

    //functions to set the user and employee variables
    const currentUser = useAuthValue();
    //console.log(currentUser.currentUser);
    
    const [employeeData, setEmployeeData] = useState({
        employee: {},
    });

    console.log(employeeData);
    
    //get the employee data from the employees collection in firestore, limit
    function getEmployeeDataById(){
      getDocs(query(collection(db, "employees"),
        where("UID", "==", currentUser.currentUser.uid), 
        limit(1))).then(docs => {
          setEmployeeData(docs.docs[0].data());
    } 
        )}
  
  
  useEffect(() => {
    getEmployeeDataById()
  }, [])


  return (
    <div className="employeeProfileHeader">
                <div className="employeeProfileHeaderName"> {employeeData.name} </div>
                <div className="employeeProfileHeaderJobTitle"> {employeeData.jobTitle} | {employeeData.department}</div>
            </div>
  )
}
